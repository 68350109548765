import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import { Link } from "gatsby"
import SEO from "../components/seo"

const WhyUsPage = () => (
  <div>
      <SEO 
        title="Why Sheep Hosting Bay" 
        description="You do not have to change hosts from now on. Get to know why Sheephostingbay will probably be your last web host."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-title">Why <span className="block text-indigo-600 xl:inline">Sheephostingbay</span></p>
              <p className="section-after-title">
              You do not have to change hosts from now on. Get to know why Sheephostingbay will probably be your last web host.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="sm:w-2/6 w-full overflow-hidden border-b-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/hosting-control-panel.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Point & Click Web Control Panel</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        With the Web Control Panel, we want to show that web site administration does not need to be hard. 
                        In reality, it may be rather simple and intuitive.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-r-2 border-l-2 border-b-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/service-scalability.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Custom DNS Records</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        The Web Control Panel will give you complete authority over all domain management aspects. Effortlessly 
                        edit all the critical DNS records, including your domain’s MX, A, NS, etc. records.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full  overflow-hidden border-b-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/cloud-hosting-platform.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Website Hosting Platform</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Our website hosting platform is entirely riskless. We guarantee a 99.9% uptime with all cloud hosting plans.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>
            
            <div className="sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/multiple-data-centers.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Multiple Data Centers</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Pick where to host your web site – in the USA, in England, in Eastern Europe or in Australia. Benefit from faster–than–light 
                        web site load times thanks to the avant–garde data centers we have chosen – the Steadfast data center in the United States, 
                        the Pulsant data center in England, the Ficolo data center in Finland, the Telepoint data center in Bulgaria, and the 
                        Amaze data center in Australia.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-r-2 border-l-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/free-website-themes.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">An Instant Site Installer</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Build your next website using a cost–free web design template in just 5 minutes using our Online Site Installer. 
                        No specific abilities are required. No setup is required either.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/a-free-site-building-tool.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Applications Installer</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Application installation is effortless now – you can forget about hunting for the latest release and dealing with 
                        application installation and setup operations. Our Applications Installer software enables you to install more 
                        than forty popular web applications with just a click. No further setup is needed.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-t-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/web-apps-installer.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Website Accelerators</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        In the Web Control Panel you’ll find a number of website accelerator tools that can help you improve the loading speed of your site significantly.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full overflow-hidden border-r-2 border-l-2 border-t-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/30-day-money-back.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A 30–Day Money–Back Guarantee</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Enjoy riskless website hosting solutions with Sheephostingbay. If you are not satisfied with us, you’ll get 
                        a refund within the next thirty days, it’s guaranteed.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

            <div className="sm:w-2/6 w-full  overflow-hidden border-t-2 border-gray-200">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/24x7-support.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">24x7 Support</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Having to wait around for the support team to respond is intolerable. And we, at Sheephostingbay, are putting our 
                        customers first. That’s the reason why Sheephostingbay offers a 24x7 support service with a 1–hour client 
                        support ticket response guarantee.
                        </p>
                        <Link to="/" className="text-sm font-semibold text-indigo-700 dark:text-indigo-400 hover:underline">Learn More</Link>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default WhyUsPage
